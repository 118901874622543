
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {},

  setup() {
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addGradeModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      //const db_data = {}
      await setStandardData("");
    });

    const formData = ref({
      name: "",
      standard_select: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Grade name is required",
          trigger: "change",
        },
      ],
    });

    const standard = ref([]);
    const setStandardData = async (data) => {
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_GRADE_STANDARD, values)
          .then(({ data }) => {
            standard.value = data.page_data;
            console.log(standard.value);
            //loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setProductGradeData = async (data) => {
      const db_data = {
        grade_name: data.name,
        standard_id: data.standard_select,
        active: data.active,
      };

      await store
        .dispatch(Actions.CUST_ADD_PRODUCT_GRADE, db_data)
        .then(({ data }) => {
          if (data.grade_id) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Product Grade has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addGradeModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setProductGradeData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addGradeModalRef,
      setStandardData,
      standard,
      active_list_data,
    };
  },
});
